
import Vue from 'vue'
import SliderTabs from '@/components/SliderTabs.vue'
import { EcosystemId } from '@/ecosystem'



export default Vue.extend({
  name: 'Token List',
  components: { SliderTabs },
  methods: {
    setEcosystem(chain_id : string) {
     this.currentChain = chain_id
    },
    copyAddress (token : string, address : string) {
      let textArea = document.createElement("textarea")
      textArea.value = address
      textArea.style.top = "0"
      textArea.style.left = "0"
      textArea.style.position = "fixed"
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      let successful = document.execCommand('copy')


      document.body.removeChild(textArea)
    },
  },
  data() {
    return {
      currentChain: 'Moonbeam',
      active: true,
      stakedOnly: false,
      includeRetired: false,
      sortBy: 'Earned',
      searchText: '',
      headers: [
      {
        text: 'Logo',
        value: 'logo',
        align: 'center',
        sortable: false
      },
      {
        text: 'Ticker',
        value: 'ticker',
        align: 'center',
        sortable: false
      },
      {
        text: 'Token name',
        value: 'name',
        align: 'center',
        sortable: false
      },
      {
        text: 'Contract address',
        value: 'address',
        align: 'center',
        sortable: false
      },
      {
        text: 'Socials',
        value: 'telegram',
        align: 'center',
        sortable: false
      },
      {
        text: 'DexScreener Link',
        value: 'chart',
        align: 'center',
        sortable: false
      }
    ],
    tokens: {

    'BSC': [
      {
        logo: require('@/assets/custom-tokens/bsc/SKC.png'),
        name: 'Swamp King Capital',
        ticker: 'SKC',
        address: '0x18e68f649f27719c185ae3d13098163336ec2f8c',
        telegram: 'https://t.me/SwampKingCapital'
      },
      ],
    'Moonriver': [

      ],
    'Moonbeam': [
      {
        logo: require('@/assets/custom-tokens/moonbeam/Bronze.png'),
        name: 'Moonbeam Ingots (Bronze)',
        ticker: 'BRONZE',
        address: '0x2d5f85444D6699b77fEE834145975c71639A75D0',
        telegram: 'https://t.me/Moonbeam_Ingots'
      },
      {
        logo: require('@/assets/custom-tokens/moonbeam/Silver.png'),
        name: 'Moonbeam Ingots (Silver)',
        ticker: 'SILVER',
        address: '0xA8c0453B31Fd1F9F03fdAA84A89845De43A68a3e',
        telegram: 'https://t.me/Moonbeam_Ingots'
      },
      {
        logo: require('@/assets/custom-tokens/moonbeam/Gold.png'),
        name: 'Moonbeam Ingots (Gold)',
        ticker: 'GOLD',
        address: '0x4dD0c86746E8C1CEf05A987b13079343A30E05eF',
        telegram: 'https://t.me/Moonbeam_Ingots'
      },
      {
        logo: require('@/assets/custom-tokens/moonbeam/LunarRise.png'),
        name: 'LunarRise',
        ticker: 'LUNAR',
        address: '0x4142d74292eae9dd1d185cabc572808c73189675',
        telegram: 'https://t.me/LunarRise_glmr'
      },
      {
        logo: require('@/assets/custom-tokens/moonbeam/Moonshiba.png'),
        name: 'MoonbeamShiba',
        ticker: 'MOONSHIB',
        address: '0xde93d50804c0ceeacb5ec5cad2db0ac71b13e44e',
        telegram: 'https://t.me/MoonbeamShiba'
      },
      {
        logo: require('@/assets/custom-tokens/moonbeam/meth.png'),
        name: 'MoreETH',
        ticker: 'METH',
        address: '0x86b8b53d0096d917b153bc506038e5db0264a553',
        telegram: 'https://t.me/METHCrosschain'
      },
    ]
    }
    }
  },
  mounted () {
    this.ecosystemId = this.$store.state.ecosystemId
  },
  beforeDestroy() {
    this.active = false
  },
  beforeRouteLeave(to: any, from: any, next: Function) {
    this.active = false
    next()
  },
  computed: {
    ecosystemId: {
      get(): EcosystemId {
        return this.$store.state.ecosystemId
      },
      set(val: EcosystemId) {
        this.$store.commit('setEcosystemId', val)
        if (val == 0) {
          this.setEcosystem("BSC")
        }
        else if (val == 1) {
          this.setEcosystem("Moonriver")
        }
        else if (val == 2) {
          this.setEcosystem("Moonbeam")
        }
      }
    },
  },
  watch: {
    ecosystemId() {
      this.$padswapTheme.theme = this.$store.getters.ecosystem.theme
    },
  }
})

